.stocks-locker {
  display: flex;
  gap: 10px;
  input {
    border: 1px solid gray !important;
    width: 60px;
  }
}
.btn-fushia {
  font-family: "Poppins";
  font-style: normal;
  color: #ffffff;
  background: linear-gradient(89.87deg, #f17caf 2.08%, #e7075b 98.27%);
}
