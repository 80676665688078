@tailwind base;
@tailwind components;
@tailwind utilities;

@import "~bootstrap/dist/css/bootstrap.min.css";
@import "../@core/scss/react/pages/page-authentication.scss";
@import "../@core/scss/base/pages/page-blog.scss";
@import "../@core/scss/base/pages/page-knowledge-base.scss";
@import "../@core/scss/base/pages/page-faq.scss";
@import "../@core/scss/react/libs/editor/editor.scss";
@import "../@core/scss/react/apps/app-users.scss";
@import "../@core/scss/base/plugins/forms/form-quill-editor.scss";
@import "../@core/scss/base/pages/page-blog.scss";
@import url("https://fonts.googleapis.com/css2?family=Public+Sans:ital,wght@0,300;0,400;0,500;0,600;0,700;1,300;1,400;1,500;1,600;1,700&display=swap");
@import "../@core/assets/fonts/feather/iconfont.css";
@import "../@core/scss/core.scss";
@import "/src/assets/scss/style.scss";

input[type="checkbox"]:checked {
  accent-color: #e7075b;
}

.was-validated .input-group > .form-control:not(:focus):invalid,
.input-group > .form-control:not(:focus).is-invalid,
.was-validated .input-group > .form-select:not(:focus):invalid,
.input-group > .form-select:not(:focus).is-invalid,
.was-validated .input-group > .form-floating:not(:focus-within):invalid,
.input-group > .form-floating:not(:focus-within).is-invalid {
  z-index: 0 !important;
}

button {
  font-family: "Poppins";
  font-style: normal;
  background: linear-gradient(89.87deg, #f17caf 2.08%, #e7075b 98.27%);
  border: 1px solid #e7075b;
  color: #ffffff;
}

button:hover {
  color: #ffffff;
}

a:hover {
  color: #ffffff;
}

a {
  color: #e7075b;
}

.primary {
  background: linear-gradient(
    89.87deg,
    #f17caf 2.08%,
    #e7075b 98.27%
  ) !important;
  border: 1px solid #e7075b !important;
  color: #ffffff !important;
}

input:focus {
  border-color: #e7075b;
  box-shadow: 0 0 0 0.2rem rgba(231, 7, 91, 0.25);
}
