.slide_image {
  img {
    max-width: 900px;
    max-height: 500px;
    object-fit: cover;
  }

  .btn-fushia {
    font-family: "Poppins";
    font-style: normal;
    color: #ffffff;
    background: linear-gradient(89.87deg, #f17caf 2.08%, #e7075b 98.27%);
  }

  h2 {
    color: #e7075b;
  }
}
