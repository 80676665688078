.carousel-dark .carousel-caption {
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    color: $carousel-dark-caption-color;
  }
}
