.btn-fushia {
  font-family: "Poppins";
  font-style: normal;
  color: #ffffff;
  background: linear-gradient(89.87deg, #f17caf 2.08%, #e7075b 98.27%);
}

h2 {
  color: #e7075b;
}

.mentionDiv ul li {
  list-style-type: square !important;
}

.mentionDiv ol li {
  list-style-type: decimal !important;
}
